@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Kempt
Version:        2.0
Primary use:    Kempt | Multipurpose Creative HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-Kempt/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../fonts/icomoon/style.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Arima+Madurai:200,300,400,500,700,800,900|Poppins:300,400,500,600,700|Dancing+Script:400,700);
 
// Initialize Variables
@import "less-Kempt/variables.less";
@import "less-Kempt/mixins.less";
// Typography
@import "less-Kempt/typography.less";

// Common Styles
@import "less-Kempt/common.less";
@import "less-Kempt/extra.less";
@import "less-Kempt/overlay.less";

// Header
@import "less-Kempt/header.less";

// Nav
@import "less-Kempt/nav.less";

// Content Blocks
@import "less-Kempt/topbar.less";
@import "less-Kempt/courses.less";
@import "less-Kempt/inner-header-title.less";
@import "less-Kempt/vertical-nav.less";
@import "less-Kempt/menu-full-page.less";
@import "less-Kempt/boxed-layout.less";
@import "less-Kempt/form.less";
@import "less-Kempt/side-push-panel.less";
@import "less-Kempt/box-hover-effect.less";
@import "less-Kempt/work-gallery.less";
@import "less-Kempt/gallery-isotope.less";
@import "less-Kempt/sliders.less";
@import "less-Kempt/home.less";
@import "less-Kempt/about.less";
@import "less-Kempt/contact.less";
@import "less-Kempt/services.less";
@import "less-Kempt/shop.less";
@import "less-Kempt/blog.less";

// Shortcodes
@import "less-Kempt/shortcodes.less";


// Widgets
@import "less-Kempt/widgets.less";


// Footer
@import "less-Kempt/footer.less";