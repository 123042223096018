/*
 * Shortcode: Testimonials.less
 * -----------------------------------------------
*/
.testimonial .content {
  position: relative;
}
.testimonial .content::after {
  color: #202c45;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 48px;
  opacity: 0.2;
  position: absolute;
  right: 20px;
  top: -15px;
}
.testimonial-wrapper .content {
  position: relative;
}
.testimonial-wrapper .content i {
  color: #333;
  font-size: 68px;
  left: 0;
  opacity: 0.06;
  position: absolute;
  right: 0;
  top: 72px;
  z-index: -1;
}