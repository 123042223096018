/*
 * Shortcode: Clients.less
 * -----------------------------------------------
*/
.clients-logo {
	.item {
		background: @gray-lighter;
		border-color: transparent;
		margin-bottom: 30px;
		padding: 0;
		.transition(all .3s ease 0s);
	}

	img {
		width: auto !important;
		display: inline-block !important;
		opacity: 0.9;

		&:hover {
			opacity: 1;
		}
	}
	
}

.clients-logo {
	&.carousel {
		text-align: center;
		.item {
			box-shadow: none;
		}
	}
}

.clients-logo {
	&.style2 {
		.item {
			box-shadow: none;

			img {
				max-height: 120px;
			}
		}
		
	}
}

.client-img {
	opacity: 0.9;
	text-align: center;
	.transition(all .3s ease 0s);

	&:hover {
		opacity: 1;
	}

	img {
	}
}

.clients-logo {
	&.carousel {
		text-align: center;
		.item {
			box-shadow: none;
			margin: 0 10px;
		}
	}
}

.clients-logo.transparent .item {
	background: transparent;
	margin-bottom: 0px;
}
.clients-logo.transparent img {
  opacity: 0.8;
  transition: all 300ms ease-in-out 0s;
}
.clients-logo.transparent img:hover {
  opacity: 1;
}