/*
 * Shortcode: Teams.less
 * -----------------------------------------------
*/
.team-members .team-member .team-thumb {
  overflow: hidden;
}
.team-members .team-member img {
    .transition(all 300ms ease-in-out 0s);
}
.team-members .team-member:hover img {
  transform: scale3d(1.1, 1.08, 1.1);
}
.team-member .team-details {
  background: #f7f8fa none repeat scroll 0 0;
  border: 1px solid #eee;  
  .transition(all 300ms ease-in-out 0s);
}
.team-member:hover .team-details h4,
.team-member:hover .team-details p,
.team-member:hover .team-details a {
    color: #fff;
    .transition(all 300ms ease-in-out 0s);
}
.team-member .team-details a {
    .transition(all 300ms ease-in-out 0s);
}